<template>
  <router-view />
</template>
<script setup lang="ts">
import { onMounted } from "vue";
import aegisMonitor, { AegisSdk } from "@hiijs/aegis-monitor";

const initMonitor = () => {
  if (import.meta.env.MODE === "production") {
    aegisMonitor.initialize({
      id: "ZEWW3HvdQLP0GKDx17",
      env:
        import.meta.env.MODE === "production"
          ? AegisSdk.environment.production
          : AegisSdk.environment.development,
      api: {
        reportRequest: true, // 接口全量上报，info 对全部用户生效
        apiDetail: true, // 上报接口的同时，也会上报接口请求参数和返回值
        retCodeHandler(data:any) {
          // data 是string类型，如果需要对象需要手动parse下
          // url 为请求url
          // xhr 响应,可以通过xhr.response拿到完整的后台响应
          try {
            data = JSON.parse(data);
          } catch (e) {}
          return {
            // isErr 如果是 true 的话，会上报一条 retcode 异常的日志。
            isErr: data.code !== 200,
            code: data.code,
          };
        },
      },
    });
  }
};

onMounted(() => {
  initMonitor();
});
</script>
<style></style>
